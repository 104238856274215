import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { ThemeContext } from "../../Theme.js";

export default function Logo(props) {

  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);

  let width = props.width || "120";
  let type = props.type || "logo";

  if (type === 'icon') {
    return (
      <Image src={'/icon.svg'} style={{ width: width, marginTop: 0, cursor: 'pointer' }} onClick={() => navigate('/')} />
    )
  } else {
    return (
      <Image src={theme && theme === 'dark-theme' ? '/logo-dark.svg' : '/logo.svg' } style={{ width: width, marginTop: 0, cursor: 'pointer' }} onClick={() => navigate('/')} />
    )
  }
  // return (
  //   <Navbar.Brand>
  //     <a href="/"><img src="/logo.svg" width={width} alt="Fuse Logo" /></a>
  //   </Navbar.Brand>
  // )
}
